// 广告页
import { request } from "@/utils/request.js"
// 前台部分

/**
 * @description 查全部供应商名称
 */
export function loadName() {
    return request('/supplier/company/selectAllName', "get", "", {}, false, true)
}


/**
 * @description 根据公司名查询
 * @param {string} name 
 */
export function searchAdInfo(param) {
    return request("/supplier/company/selectByName", "get", param, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}


/**
 * @description 换一批供应商
 * @param {string} superiorityBusiness 优势业务
 * @param {string} superiorityCourse 	优势航线
 * @param {int32} type   1.货代,2.车队,3.报关行
 * @returns 
 */
export function queryAdInfo(param) {
    return request("/supplier/type/inBatch", "post", param,)
}

// 广告页详情
export function queryAdDetail(id) {
    return request(`/supplier/company/selectById?id=${id}`, "get", "", {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}


// 后台部分

/**
 * @description 分页查公司部分信息
 * @param {int32} current 当前页
 * @param {int32} size   页码尺寸
 * @returns 
 */
export function queryAdvDeatil(param) {
    return request(`/supplier/company/selectPage`, "post", param)
}

// 删除公司
export function deleteAdv(id) {
    return request(`/supplier/company/delete?id=${id}`, "get", "", {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}


/**
 * @description 增加供应商
 * @param {string} address 地址
 * @param {string} area    区
 * @param {string} city    市
 * @param {string,true} email  邮箱
 * @param {string} name   公司名称
 * @param {string,true} phone   电话
 * @param {string} position    宁波/上海/青岛等..
 * @param {string} profile    简介
 * @param {string} province  省
 * @param {Array} supContactList   联系人集合
 * @returns 
 */
export function addAdv(param) {
    return request('/supplier/company/save', "post", param)
}


// 编辑广告公司信息
export function updateAdv(param) {
    return request("/supplier/company/update", "post", param)
}





