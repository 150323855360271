<template>
    <div class="advertisement">
        <div class="the_certer">
            <!-- 左侧 -->
            <div class="theleft">
                <el-button
                    class="tomanage"
                    type="text"
                    v-if="isAdmin"
                    @click="toManage()"
                >
                    后台管理
                </el-button>
                <el-button class="to_change" type="text" @click="query()">
                    换一批
                </el-button>
                <!-- 搜索框 -->
                <el-tabs
                    type="border-card"
                    v-model="activeName"
                    style="margin-bottom: 2rem"
                    @tab-click="handleClick"
                >
                    <el-tab-pane
                        label="找货代"
                        name="trafficFee"
                        :disabled="requestCpListLoading"
                    >
                        <span slot="label">
                            <i class="el-icon-ship"></i>
                            找货代
                        </span>

                        <div
                            style="display: flex; width: 100%"
                            class="search_traffic_bar"
                        >
                            <div
                                style="
                                    display: flex;
                                    flex-grow: 1;
                                    border: 1px solid #dcdfe6;
                                "
                            >
                                <div
                                    style="
                                        flex: 1;
                                        display: flex;
                                        align-items: center;
                                    "
                                >
                                    <div
                                        style="
                                            text-align: center;
                                            width: 60px;
                                            font-size: 15px;
                                        "
                                    >
                                        货代
                                    </div>
                                    <el-divider
                                        direction="vertical"
                                    ></el-divider>
                                    <span style="padding-right: 5px">
                                        业务:
                                    </span>
                                    <el-select
                                        v-model="business"
                                        placeholder="请选择业务类型"
                                        filterable
                                        clearable
                                        style="flex: 1"
                                    >
                                        <el-option
                                            v-for="item in forwarderlist"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        ></el-option>
                                    </el-select>
                                    <el-divider
                                        direction="vertical"
                                    ></el-divider>
                                </div>
                                <div
                                    style="
                                        flex: 1;
                                        display: flex;
                                        align-items: center;
                                    "
                                >
                                    <span style="padding-right: 5px">
                                        优势航线:
                                    </span>
                                    <el-select
                                        v-model="advantageRoutes"
                                        placeholder="请选择航线"
                                        filterable
                                        clearable
                                        style="flex: 1"
                                    >
                                        <el-option
                                            v-for="item in routelist"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        ></el-option>
                                    </el-select>
                                    <el-divider
                                        direction="vertical"
                                    ></el-divider>
                                </div>
                                <el-input
                                    placeholder="请输入公司名字"
                                    v-model="searchCompanyName"
                                    style="flex: 1"
                                ></el-input>
                            </div>
                            <el-button
                                :disabled="requestCpListLoading"
                                icon="el-icon-search"
                                type="primary"
                                @click="
                                    searchCompanyName
                                        ? searchCompany()
                                        : query()
                                "
                            >
                                搜索
                            </el-button>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane name="agent" :disabled="requestCpListLoading">
                        <span slot="label">
                            <i class="el-icon-edit"></i>
                            找车队
                        </span>
                        <div class="search_traffic_bar" style="display: flex">
                            <div
                                style="
                                    display: flex;
                                    border: 1px solid #dcdfe6;
                                    flex: 1;
                                    align-items: center;
                                "
                            >
                                <div
                                    style="
                                        text-align: center;
                                        width: 60px;
                                        font-size: 15px;
                                    "
                                >
                                    车队
                                </div>
                                <div
                                    style="
                                        flex: 1;
                                        display: flex;
                                        align-items: center;
                                    "
                                >
                                    <el-divider
                                        direction="vertical"
                                    ></el-divider>
                                    <el-input
                                        placeholder="请输入公司名字"
                                        v-model="searchCompanyName"
                                        style="flex: 1"
                                    ></el-input>
                                </div>
                            </div>
                            <el-button
                                :disabled="requestCpListLoading"
                                icon="el-icon-search"
                                type="primary"
                                @click="
                                    searchCompanyName
                                        ? searchCompany()
                                        : query()
                                "
                            >
                                搜索
                            </el-button>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane
                        name="customsBroker"
                        :disabled="requestCpListLoading"
                    >
                        <span slot="label">
                            <i class="el-icon-printer"></i>
                            找报关行
                        </span>
                        <div class="search_traffic_bar" style="display: flex">
                            <div
                                style="
                                    display: flex;
                                    border: 1px solid #dcdfe6;
                                    flex: 1;
                                    align-items: center;
                                "
                            >
                                <div
                                    style="
                                        text-align: center;
                                        width: 60px;
                                        font-size: 15px;
                                    "
                                >
                                    报关行
                                </div>
                                <div
                                    style="
                                        flex: 1;
                                        display: flex;
                                        align-items: center;
                                    "
                                >
                                    <el-divider
                                        direction="vertical"
                                    ></el-divider>
                                    <el-input
                                        placeholder="请输入公司名字"
                                        v-model="searchCompanyName"
                                        style="flex: 1"
                                    ></el-input>
                                </div>
                            </div>
                            <el-button
                                icon="el-icon-search"
                                type="primary"
                                :disabled="requestCpListLoading"
                                @click="
                                    searchCompanyName
                                        ? searchCompany()
                                        : query()
                                "
                            >
                                搜索
                            </el-button>
                        </div>
                    </el-tab-pane>
                </el-tabs>
                <!-- 列表 -->
                <el-skeleton :rows="6" animated :loading="requestCpListLoading">
                    <template slot="template">
                        <el-card
                            shadow="hover"
                            v-for="item in 4"
                            body-style="width:100%;"
                            :key="item"
                            class="company_card"
                        >
                            <div style="height: 150px">
                                <el-skeleton-item
                                    variant="h3"
                                    style="height: 100%"
                                />
                            </div>
                        </el-card>
                    </template>
                    <template>
                        <!-- showList 用来展示的列表-->
                        <el-empty
                            :image-size="200"
                            description="更多供应商，持续更新中..."
                            v-if="!(showList && showList.length > 0)"
                        ></el-empty>
                        <el-card
                            shadow="hover"
                            v-for="item in showList"
                            body-style="width:100%;padding:20px;"
                            :key="item.id"
                            class="company_card"
                        >
                            <div
                                style="
                                    display: flex;
                                    height: 140px;
                                    align-items: center;
                                "
                            >
                                <div class="company_logo_container">
                                    <el-image :src="item.uri" :fit="'fit'">
                                        <div slot="error" class="image-slot">
                                            <i
                                                class="el-icon-picture-outline"
                                            ></i>
                                        </div>
                                    </el-image>
                                </div>
                                <div
                                    style="
                                        display: flex;
                                        flex: 1;
                                        justify-content: space-between;
                                    "
                                >
                                    <div
                                        style="
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: space-between;
                                            min-height: 130px;
                                        "
                                    >
                                        <el-row
                                            type="flex"
                                            align="middle"
                                            style="margin-bottom: 1rem"
                                        >
                                            <div class="theBig_title">
                                                {{ item.name }}
                                            </div>
                                            <el-tag
                                                size="small"
                                                v-for="citem in item.supType"
                                                :key="citem"
                                                style="margin-right: 5px"
                                                :type="
                                                    judgeType(citem, 'color')
                                                "
                                            >
                                                {{ judgeType(citem, "name") }}
                                            </el-tag>
                                        </el-row>
                                        <div
                                            style="
                                                margin: 0 0 0 2rem;
                                                display: flex;
                                                flex-direction: column;
                                            "
                                        >
                                            <div
                                                style="
                                                    display: flex;
                                                    align-items: center;
                                                "
                                                v-if="
                                                    [
                                                        `trafficFee`,
                                                        `customsBroker`,
                                                    ].includes(activeName)
                                                "
                                            >
                                                {{
                                                    activeName === `trafficFee`
                                                        ? `优势业务：`
                                                        : "报关行类型："
                                                }}
                                                <el-tag
                                                    size="small"
                                                    v-for="citem in activeName ===
                                                    'trafficFee'
                                                        ? item.superiorityBusinessForwarder
                                                        : item.superiorityBusinessCustoms"
                                                    :key="citem"
                                                    style="margin: 4px"
                                                >
                                                    {{ citem }}
                                                </el-tag>
                                            </div>

                                            <div
                                                v-if="
                                                    activeName === `trafficFee`
                                                "
                                            >
                                                优势航线：
                                                <el-tag
                                                    size="small"
                                                    v-for="ship in item.superiorityCourseForwarder"
                                                    :key="ship"
                                                    type="success"
                                                    style="margin: 4px"
                                                >
                                                    {{ ship }}
                                                </el-tag>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style="
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: space-between;
                                            align-items: center;
                                        "
                                    >
                                        <span>
                                            <i
                                                style="width: 20px"
                                                class="el-icon-location-information"
                                            ></i>
                                            <span>{{ item.position }}</span>
                                        </span>
                                        <el-button
                                            type="primary"
                                            class="buttonClass"
                                            @click="toLookDetail(item.id)"
                                        >
                                            查看详情
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </template>
                </el-skeleton>
            </div>
            <!-- 右侧广告轮播 -->
            <el-card class="theright">
                <div class="ad_box" @click="toDetail">
                    <div class="ad_title">放舱网签约企业</div>
                    <el-skeleton
                        :rows="6"
                        animated
                        :loading="requestAdListLoading"
                    >
                        <template slot="template">
                            <ul style="padding: 14px">
                                <li
                                    v-for="item in 12"
                                    :key="item"
                                    class="ad_item"
                                >
                                    <el-skeleton-item variant="text" />
                                </li>
                            </ul>
                        </template>
                        <template>
                            <vueSeamlessScroll
                                :data="newmsg"
                                :class-option="optionCustomer"
                                class="ad_scroll"
                            >
                                <ul>
                                    <li
                                        v-for="item in newmsg"
                                        :key="item.id"
                                        :data-id="JSON.stringify(item.id)"
                                        class="ad_item"
                                    >
                                        <i class="icon"></i>
                                        <span class="ad_content">
                                            {{ item.name }}
                                        </span>
                                    </li>
                                </ul>
                            </vueSeamlessScroll>
                        </template>
                    </el-skeleton>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { checkRole } from "@/utils/index";
import { loadName, queryAdInfo, searchAdInfo } from "@/api/advertisement";
export default {
    name: "Advertisement",
    components: {
        vueSeamlessScroll,
    },
    data() {
        return {
            //搜索状态下的全部参数
            showList: [],
            // 请求loading
            requestAdListLoading: true,
            requestCpListLoading: true,
            // 搜索参数
            business: "",
            advantageRoutes: "",
            newmsg: [],
            searchCompanyName: "",
            routelist: [
                "欧洲",
                "地中海",
                "美加",
                "黑海",
                "中南美",
                "印巴",
                "港台",
                "日韩",
                "远东",
                "非洲",
                "大洋洲",
                "东南亚",
                "全航线",
            ],
            forwarderlist: [
                "海运普柜",
                "海运拼箱",
                "特种柜",
                "冷柜",
                "国际铁路",
                "海铁联运",
                "海陆联运",
                "空运",
                "散货",
            ],
            optionCustomer: {
                step: 1,
                limitMoveNum: 13, //限制开始滚动的条数
                openTouch: false,
                waitTime: 50, //滚动的速度
                direction: 1, //方向 0 往下 1 往上 2向左 3向右
                singleWidth: 30,
            },
            activeName: "trafficFee",
            isAdmin: false,
        };
    },
    mounted() {
        // 供应商列表
        this.query();
        // 全部供应商名称
        this.querymsg();
        this.isAdmin = checkRole("admin");
    },

    methods: {
        // 判断类型
        judgeType(value, type) {
            const typeName = {
                1: "货代",
                2: "车队",
                3: "报关行",
            };
            const typeColor = {
                1: "warning",
                2: "danger",
                3: "info",
            };
            return type === "name" ? typeName[value] : typeColor[value];
        },
        querymsg() {
            //加载广告条信息
            loadName()
                .then(({ data }) => {
                    this.newmsg = data.data.map(({ id, name }) => ({
                        id,
                        name,
                    }));
                })
                .finally(() => {
                    this.requestAdListLoading = false;
                });
        },
        // 搜索企业
        searchCompany() {
            this.requestCpListLoading = true;
            searchAdInfo({ name: this.searchCompanyName })
                .then(({ data }) => {
                    // 处理返回数据logo不显示
                    this.showList = [
                        ...data.data.map((item) => ({
                            ...item,
                            uri:
                                item.supResourceList.find(
                                    ({ type }) => type === 1,
                                )?.uri || item.uri,
                        })),
                    ];
                })
                .finally(() => {
                    this.requestCpListLoading = false;
                });
        },
        handleClick() {
            // 切换清空数值
            this.business = "";
            this.searchCompanyName = "";
            this.advantageRoutes = "";
            this.query();
        },
        toDetail(e) {
            // 修复点击失败,把方法委托给父级（右侧列表查看详情）
            const item = e.target.closest(".ad_item");
            if (item) {
                const { id } = item.dataset;
                let newpage = this.$router.resolve({
                    path: "/advDetail",
                    query: {
                        id,
                    },
                });
                window.open(newpage.href, "_blank");
            }
        },
        toLookDetail(id) {
            // 卡片查看详情
            let newpage = this.$router.resolve({
                path: "/advDetail",
                query: {
                    id,
                },
            });
            window.open(newpage.href, "_blank");
        },
        toManage() {
            //前往管理界面
            this.$router.push({
                path: "/advManage",
            });
        },
        //换一批供应商
        query() {
            const type = {
                trafficFee: 1,
                agent: 2,
                customsBroker: 3,
            };
            const data = {
                // 供应商类型
                type: type[this.activeName],
                // 优势航线
                superiorityCourse: this.advantageRoutes || undefined,
                // 业务优势
                superiorityBusiness: this.business || undefined,
            };
            this.requestCpListLoading = true;
            queryAdInfo(data)
                .then(({ data }) => {
                    this.showList = data.data;
                })
                .finally(() => {
                    this.requestCpListLoading = false;
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
.advertisement {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.the_certer {
    margin: 2rem auto;
    width: 124rem;
    display: flex
}

.theleft {
    position:relative;
    margin-right: 2rem;
    min-width: 85rem;
    flex: 1;
}

.theright {
    padding: 1.5rem;
    height: 520px;
    background: rgba(255, 255, 255, 0.7);
}

.right_border {
    border: 0.5px solid rgba(0, 0, 0, 0.2);
}

.tr_all:nth-child(2n) {
    background: #dcdcdc;
    margin: 0;
    padding: 0;
}

.tr_all {
    height: 12.5rem;
    position: relative;
}

.border_span {
    border: 1px solid grey;
    margin: 0rem 3px 1rem 3px;
    padding: 5px;
}

.orange_span {
    color: orange;
    margin: 0 5px;
}

.company_logo_container {
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    width: 130px;
    height: 130px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center
}

.company_logo {
    width: 9rem;
    height: 9rem;
}

.buttonClass {
    width: 80px;
    height: 30px;
    padding: 0;
}

.theBig_title {
    font-size: 20px;
    font-weight: bold;
    margin: 0 1rem 0 2rem;
}

.active {
    color: red;
}

#box {
    overflow: hidden;
    height: 42rem;
    margin-bottom: 1.5rem;
    font-size: 16px;
}

.marquee_top {
    transition: all 0.5s;
    margin-top: -17px;
    /* 容器高度 */

}

.ad_name {
    cursor: pointer;
}

.ad_name:hover {
    color: white;
    background: #dcdcdc;
}

.people_div {
    margin: 2rem;
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .5);
    background: #dcdcdc;
}

.people_span {
    display: inline-block;
    margin: 10px 15px 10px 20px;
}

.ad_title {
    color: #fd593b;
    margin: 10px 10px 0 10px;
    padding: 10px 0;
    border-bottom: 1px solid #eff2f5;
    font-size: 16px;
    font-weight: bold;
}

.ad_box {
    width: 300px;
    display: inlile-block;
    border: 1px solid #ddd;
    box-sizing: border-box;
    margin-left: 0.5%;
    border-radius: 5px;
}

.ad_scroll {
    margin: 0 10px;
    width: 93%;
    height: 403px;
    min-height: 25px;
    line-height: 25px;
    overflow: hidden;
    display: inline-block
}

.ad_item {
    padding: 5px 0;
    height: 20px;
    list-style: none;
    cursor: pointer;
}

.icon {
    display: block;
    float: left;
    width: 4px;
    height: 4px;
    vertical-align: middle;
    background-color: #ff8f28;
    margin: 10px 10px 0 10px
}

.ad_content {
    width: 85%;
    font-size: 14px;
    height: 20px;
    display: inline-block;
    color: #428bca;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.company_card {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    border-radius: 12px
}

.el-select {
    width: 150px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

::v-deep .el-tabs__header {
    margin: 0;
    border: none;
}

::v-deep .search_traffic_bar .el-input .el-input__inner{
    line-height:30px;
    height:30px;
}

::v-deep .search_traffic_bar .el-select__caret{
    line-height:30px;
    height:30px;
}

::v-deep .search_traffic_bar .el-button{
    padding-top:0
    padding-bottom:0
    line-height:30px
}

::v-deep .search_traffic_bar .el-select .el-input.is-focus .el-input__inner{
    border-color:white;
}

::v-deep .el-tabs__item.is-active {
    background-color: white
}

/deep/.el-input__inner {
    border: 1px solid white;
}

.toManage_button {
    text-align: center;
    margin-top: 1rem;
}
.tomanage{
    position:absolute;
    z-index:2;
    right:70px;
}

.to_change{
    position:absolute;
    z-index:2;
    right:16px
}
</style>
